import "./style.css";
import logo from "../../images/logoFoot.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import foot from '../../images/foot.jpg'
import footMin from '../../images/foot-min.jpg'
export default function Footer() {
  return (
    <div className="footer">
      <img src={foot} alt="" className="footPic max" />
      <img src={footMin} alt="" className="footPic min" />
      <div className="footerBottom">
      <div className="foot">
        <h3>Ebuy</h3>
        <h3><a href="/qui-sommes-nous"> Qui sommes-nous ?</a></h3>
        <h3><a href="/nos-marques">Nos marques</a></h3>
        <h3><a href="/politique-de-confidentialite">Politiques de Confidentialités</a></h3>
      </div>
      <div className="footMiddle">
        <div>
        <img src={logo} alt="" className="logoFoot" />
        </div>
        <div className="socialFoot">
         <a href="https://www.facebook.com/profile.php?id=61556332296913" target="_blank" rel="noreferrer">
          <FacebookIcon fontSize="medium"/></a> 
         <a href="https://www.instagram.com/ebuy.tunisie" target="_blank" rel="noreferrer">
          <InstagramIcon fontSize="medium"/></a>
         <a href="https://www.youtube.com/@EbuyTunisia" target="_blank" rel="noreferrer">
           <YouTubeIcon fontSize="medium"/></a>
        </div>
        <div className="socialFootPower">
        Développé par   <a
                className="navLinkFoot"
                href="https://h4c.pro/"
              > by H4C </a>
               </div>
      </div>
      <div className="foot">
        
        <h3>BESOIN D'AIDE?<br/>Nous sommes là pour vous </h3>
        
        <span>Hotline clients <SupportAgentIcon sx={{color:'white',marginLeft:'1px'}}/></span>
        <span>Lundi - Vendredi : 10h - 17h</span>
        <a href="tel:26380004"><Button  sx={{color:'white',fontFamily:'Exo'}} startIcon={<LocalPhoneIcon/>}>26 380 004</Button> </a>
        <a href="whatsapp://send?phone=+216 26380004"><Button sx={{color:'white',fontFamily:'Exo'}} startIcon={<WhatsAppIcon/>}>26 380 004</Button> </a>
        <a href="mailto:hello.ebuytn@gmail.com"><Button sx={{color:'white',fontFamily:'Exo'}} startIcon={<MailIcon/>}>hello.ebuytn@gmail.com</Button> </a>
      </div>
      
      </div>
    </div>
  );
}
